<template>
	<div>
		<div style="margin:20px">
			<el-table :data="tableData" border style="" :header-cell-class-name="headerBg">
				<el-table-column prop="type" label="来源" width="">
					<template slot-scope="scope">
						<a-tag>{{ scope.row.type == 2 ? '登录' : '注册' }}</a-tag>
					</template>
				</el-table-column>
				<el-table-column prop="amount" label="积分" width="">
				</el-table-column>
				<el-table-column prop="insertdate" label="日期">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import {
		scorelist
	} from '@/api/home/index'
	export default {
		data() {
			return {
				tableData:null,
				userInfo:null,
			}
		},

		created() {
			this.userInfo = JSON.parse(sessionStorage.getItem("getUserInfo"))
			this.scorelist()
		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			//---------------我的消费积分记录接口-------------------------
			scorelist:function(){
				let data = {
					uid:this.userInfo.uid,
					pageNum: 0,
					pageSize: 0,
				}
				scorelist(data).then(res=>{
					if(res.code=="200"){
						this.tableData = res.data;
					}
				})
			},
			
			headerBg:function({row,rowIndex}){
				if(rowIndex == 0){
					return 'headerBgClass'
				}
			}
		}
	}
</script>

<style lang="less">
	.headerBgClass{
		background-color:#f9f9f9 !important;
	}
</style>